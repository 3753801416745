/* eslint-disable no-unused-vars */
import moment from 'moment';
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { Container } from '@bvcco/bvc-digital-market-component-library';

import App from '@config/app.js';
import useFetch from '@hooks/useFetch';
import News from '@components/News/News';
import useRouter from '@hooks/useRouter';
import { getTabsSeo } from '@utils/tabs';
import Ticker from '@components/Ticker/Ticker';
import Events from '@components/Events/Events';
import Layout from '@components/Layout/Layout';
import { getLocaleCmsCookie, getLocalToken } from '@utils/cookies';
import { getDateTwentyFourHours } from '@utils/dates';
import useHomeSnippets from '@hooks/snippets/useHomeSnippets';
import MarketLevel1 from '@components/MarketLevel1/MarketLevel1';
import { validateSession } from '@utils/session';

const initialParams = {
  categoriesIds: [''],
  initialDate: moment(),
  finalDate: getDateTwentyFourHours(),
  allEvents: ''
};
const fetchHomeData = async (
  currentLocale,
  params = initialParams,
  localToken
) => {
  const { cms, api } = App.getInstance();
  const [
    homeData,
    menuData,
    layoutData,
    modalLogin,
    events,
    newsCategoriesData,
    localMarketTabs,
    upcomingEvents,
    eventCategories,
    marketLevelState,
    disableModeN2S,
    hideGraphicLevelOne,
    // bvcStock,
    tickerData,
    favicon
  ] = await Promise.all([
    cms.getHome(currentLocale),
    cms.getMenu(currentLocale),
    cms.getLayout(currentLocale, { identifier: 'home' }),
    cms.getLoginModal(currentLocale),
    cms.getEvents(currentLocale, params),
    cms.getNewsCategories(currentLocale),
    cms.getLocalMarketTabs(currentLocale, {
      marketLevel: '1'
    }),
    cms.getUpcomingEvents(currentLocale, {
      actualDate: moment()
    }),
    cms.getEventCategories(currentLocale),
    cms.getMarketLevelState(currentLocale, { level: 1 }),
    cms.getDisableModeN2(currentLocale, {
      marketLevel: 1
    }),
    cms.getHideGraphicLevelOne(currentLocale),
    // api.bvcStock.get()
    api.ticker.getTicker(),
    cms.getFavicon(currentLocale)
  ]);
  layoutData.seo = getTabsSeo({
    query: params?.query,
    layoutDataSeo: layoutData?.seo,
    tabs: localMarketTabs
  });
  const user = await validateSession(
    localToken,
    process.env.NEXT_PUBLIC_BVC_BASE_VALIDATE_SESSION,
    process.env.NEXT_PUBLIC_DOMAIN
  );
  return {
    props: {
      homeData: {
        ...homeData,
        events,
        eventCategories,
        newsCategories: newsCategoriesData,
        upcomingEvents,
        // bvcStock: bvcStock || null,
        localMarketTabs,
        tickerData: tickerData || null,
        marketLevelState: marketLevelState.pdMarketLevel || false
      },
      layoutData: {
        ...menuData,
        ...layoutData,
        modalLogin,
        favicon,
        user
      },
      disableModeN2S: { ...disableModeN2S },
      hideGraphicLevelOne
    }
  };
};

const Index = (props) => {
  const router = useRouter();
  const [eventsParams, setEventsParams] = useState({
    ...initialParams,
    query: router.query
  });
  const displayEvents = false;
  const [{ homeData, layoutData, disableModeN2S, hideGraphicLevelOne }] =
    useFetch(props, fetchHomeData, eventsParams);

  const bvcAction = homeData?.bvcStock ? homeData.bvcStock : null;
  const snippets = useHomeSnippets({
    news: homeData.interestingInformation,
    events: homeData.upcomingEvents
  });

  const Banner = dynamic(() => import('@components/Banner/Banner'), {
    ssr: false
  });
  const hideTicker = hideGraphicLevelOne.filter(
    (elem) => !!(elem.marketIdentifier === 'ticker' && elem.active)
  );

  const renderBanner = useCallback(() => {
    return (
      <Banner
        slider={homeData.banner}
        bvcAction={bvcAction}
        id='home__banner'
      />
    );
  }, [homeData, bvcAction]);

  return (
    <Layout
      layoutData={layoutData}
      meta={layoutData.seo.meta}
      title={layoutData.seo.title}
      showBreadcrumb={false}
      snippets={snippets}
      favicon={layoutData.favicon}
    >
      {renderBanner()}
      <Container>
        <MarketLevel1
          {...homeData.localMarket}
          tabs={homeData.localMarketTabs}
          isActive={homeData.marketLevelState.isActive}
          disabledModeData={disableModeN2S}
          hideGraphicLevelOne={hideGraphicLevelOne}
          tickerData={homeData.tickerData}
          hideTicker={hideTicker.length !== 0}
          showHeaderLogin={layoutData.headerLogin.showHeaderLogin}
        />
        {displayEvents && (
          <Events
            filters={eventsParams}
            updateFilters={setEventsParams}
            content={homeData.calendarContent}
            eventsSelectedDay={homeData.events}
            upcomingEvents={homeData.upcomingEvents}
            filtersOptions={homeData.eventCategories}
          />
        )}
      </Container>
      <News
        news={homeData.interestingInformation}
        categories={homeData.newsCategories}
      />
    </Layout>
  );
};

export const getServerSideProps = async (ctx) =>
  fetchHomeData(
    getLocaleCmsCookie(ctx),
    {
      ...initialParams,
      query: ctx.query
    },
    getLocalToken(ctx)
  );

Index.propTypes = {
  homeData: PropTypes.shape({
    calendarContent: Events.propTypes.content,
    upcomingEvents: Events.propTypes.upcomingEvents,
    eventCategories: Events.propTypes.filters,
    tickerData: PropTypes.shape(Ticker.propTypes.initialData)
  }),
  layoutData: PropTypes.shape(Layout.propTypes.layoutData)
};

Index.defaultProps = {
  homeData: {},
  layoutData: {}
};

export default Index;
