import { useSelector } from 'react-redux';
import { useRef, useState, useEffect } from 'react';

import Loader from '@config/lib/loader';
import useConfig from '@hooks/useConfig';
import { cmsLocales } from '@config/constants';

const useFetch = (props, request, requestParams = {}, refreshOn = null) => {
  const isFirstLoad = useRef(true);
  const loader = Loader(true);
  const [{ language }] = useConfig();
  const [data, setData] = useState(props);
  const rehydrated = useSelector(({ _persist }) => _persist.rehydrated);

  const fetchCmsData = async () => {
    loader.start();
    const { props: response } = await request(
      cmsLocales[language],
      requestParams
    );
    if (response) {
      setData(response);
    }
    loader.done();
  };

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetchCmsData();
    } else if (rehydrated) {
      isFirstLoad.current = false;
    }
  }, [request, language, rehydrated, refreshOn]);

  return [data, fetchCmsData];
};

export default useFetch;
