const { cleanKey } = require('@hooks/useDefaultTabFromUrl');

export const mergeSeoTabs = (
  layoutSeo,
  tabs,
  {
    tabIndex = 0,
    subTabIndex = 0,
    hasSubTabs = true,
    subTabsKey = 'subTabs'
  } = {}
) => {
  const tab = tabs[tabIndex] || {};
  const tabSeo = tab?.seo || {};
  const tabMeta = tabSeo?.meta || {};
  const subTab = (tab[subTabsKey] && tab[subTabsKey][subTabIndex]) || {};
  const subTabSeo = subTab?.seo || {};
  const subTabMeta = subTabSeo?.meta || {};

  return {
    ...layoutSeo,
    ...tabSeo,
    ...(hasSubTabs ? subTabSeo : {}),
    meta: {
      ...(layoutSeo?.meta || {}),
      ...tabMeta,
      ...(hasSubTabs ? subTabMeta : {})
    }
  };
};

export const getTabsSeo = ({
  query = {},
  layoutDataSeo,
  tabs,
  hasSubTabs = true,
  subTabsKey = 'subTabs',
  titleTabKey = 'title',
  titleSubTabKey = 'title',
  queryTabKey = 'tab'
}) => {
  const tab = query[queryTabKey] || '';
  let seo;
  if (!tab || !tab.length) {
    seo = mergeSeoTabs(layoutDataSeo, tabs, { subTabsKey });
  } else {
    const tabIndexOfUrl = tabs.findIndex(
      (e) => cleanKey(e[titleTabKey]) === tab.split('_')[0]
    );

    const thereAreTabOfUrl = tabIndexOfUrl > -1;
    if (thereAreTabOfUrl && hasSubTabs) {
      const subTabIndexOfUrl = tabs[tabIndexOfUrl][subTabsKey].findIndex(
        (e) => cleanKey(e[titleSubTabKey]) === tab.split('_')[1]
      );
      seo = mergeSeoTabs(layoutDataSeo, tabs, {
        tabIndex: tabIndexOfUrl,
        subTabIndex: subTabIndexOfUrl === -1 ? 0 : subTabIndexOfUrl,
        subTabsKey
      });
    } else {
      seo = mergeSeoTabs(layoutDataSeo, tabs, {
        hasSubTabs: false,
        subTabsKey
      });
    }
  }
  return seo;
};
