import slugify from 'slugify';
import { useState, useEffect } from 'react';

import { getListSnippet } from '@utils/snippets';

const useHomeSnippets = ({ news }) => {
  const [snippets, setSnippets] = useState([]);

  useEffect(() => {
    const itemListSnippet = news.map(({ title, id }, index) => ({
      '@type': 'ListItem',
      position: index,
      url: `${process.env.NEXT_PUBLIC_URL}/news/${slugify(`${title} ${id}`, {
        lower: true
      })}`
    }));
    const newsSnippet = getListSnippet({
      items: itemListSnippet,
      type: 'ItemList'
    });
    setSnippets([newsSnippet]);
  }, [news]);

  return snippets;
};

export default useHomeSnippets;
